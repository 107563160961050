<template>
  <v-container>
    <!-- {{ detected }}
    <p>--------------</p>
    {{ wiliotDetectedArray }}
    <p>--------------</p>
    {{ validOperation }}
    <p>--------------</p>
    {{ dataFile }} -->
    <v-row>
      <v-col>
        <h2>Validación</h2>
        <v-select
          color="black"
          v-model="validation"
          :items="items"
          label="Tipo de Validación"
        ></v-select>
    </v-col>
  </v-row>

  <v-row  no-gutters>
    <v-col cols="5">
      <!-- <p class="mt-3" v-if="nameFile">Archivo: {{ nameFile }}</p> -->
      <input type="file" accept=".csv" ref="inputFile" class="d-none" @change="selectFile($event)">
      <v-btn class="white--text" color="black" :disabled="!validation || dataFile.length>0" @click="$refs.inputFile.click();" >Carga CSV...</v-btn>
      <v-btn class="white--text ml-3" color="black" :disabled="dataFile.length==0" @click="clean()">Limpiar</v-btn>
      

      <v-simple-table dense class="mt-5">
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">Sku</th>
                <th class="text-left">Cantidad</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in dataFile" :key="item.sku">
                <td>{{ item.sku }}</td>
                <td>{{ item.amount }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>

        <!-- <h5 class="mt-3">Elementos totales: {{ totalDetectedCsv }}</h5> -->

    </v-col>

    <v-spacer></v-spacer>

    <v-col cols="6">
      <h4>Elementos detectados</h4>
      <v-simple-table dense class="mt-8">
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">Sku</th>
              <th class="text-left">Cantidad Orden de Compra</th>
              <th class="text-left">Cantidad Detectados</th>
              <th class="text-left">Válido</th>

            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in wiliotDetectedArray" :key="item.sku">
              <td>{{ item.sku }}</td>
              <td>{{ item.amountDataFile }}</td>
              <td>{{ item.amountWiliot }}</td>
              <td>
                <v-chip v-if="item.valid" class="ma-2" color="green" text-color="white">Si</v-chip>
                <v-chip v-if="!item.valid" class="ma-2" color="red" text-color="white">No</v-chip>
              </td>
            </tr>
          </tbody>
        </template>
        </v-simple-table>
    </v-col>
  </v-row>

  <v-row>
    <v-col>
      <v-btn large block class="white--text" color="black" :disabled="!validOperation" @click="sync(validation, detected)">Sincronizar</v-btn>
    </v-col>
  </v-row>

  <v-snackbar v-model="snackbar" :timeout="timeout">Archivo inválido, solo CSV soportado</v-snackbar>

</v-container>
</template>

<script>
import router from '../router/index'
import Swal from 'sweetalert2'
import {db} from '../firebase'
import {ref, onValue, update} from "firebase/database";
import { mapState } from 'vuex'

export default {
  data() {
    return {
      //Snackbar
      snackbar: false,
      timeout: 3500,

      //preDetected: ['122007105025','122007105025','122007105025','122007105026','122007105026'],

      nameFile: '',
      dataFile: [],
      localFile: null,
      validation: '',
      items: ['Recepción de compra', 
        'Traspaso - Salida de mercancía', 
        'Traspaso - Entrada de mercancía'],
    }
  },

  computed: {
    ...mapState(['pixels']),
    //Sumatoria final en tabla de csv
    /* totalDetectedCsv (){
      let detected = 0
      this.dataFile.forEach(e =>{
        detected += e.amount
      })
      return detected
    }, */

    //Detected, se obtienen del array original
    detected (){
      return this.pixels.filter(e => e.locationWiliot == 'VALIDACION')
      //return detectedArray.flatMap(e => e.sku)
    },

    //Construye arreglo de elementos detectados por estación wiliot
    wiliotDetectedArray (){
      if(this.dataFile.length > 0){
        const myArray = []
        const allSku = this.detected.map(e => e.sku)
        //console.log("allSku: "+allSku)
        const unicSku = [...new Set(allSku.map(e => e))]
        //console.log("unicSku: "+unicSku)
        unicSku.forEach(item => {
          const itemDataFile = this.dataFile.filter(e => e.sku == item)
          const myBulk = allSku.filter(e => e == item)
          const myObj = {
            sku:myBulk[0],
            amountWiliot:myBulk.length,
            amountDataFile:itemDataFile[0].amount,
            valid:myBulk.length == itemDataFile[0].amount ? true : false
          }
         myArray.push(myObj)
        })
        return myArray
      } else {
        return []
      }
    },

    //Valida que todas las entradas en en array de detecciones de wiliot sean 'valid'
    validOperation (){
      const allValids = this.wiliotDetectedArray.filter(e => e.valid == true)
      if((allValids.length == this.wiliotDetectedArray.length) && this.wiliotDetectedArray.length == this.dataFile.length && allValids.length > 0){
        return true
      } else {
        return false
      }
    }
  },

  methods: {
    sync(validation, detected){
      let location = ''

      switch(validation){
        case 'Recepción de compra':
          location = 'ALMACEN'
          break
        case 'Traspaso - Salida de mercancía':
          location = 'TRASPASO'
          break
        case 'Traspaso - Entrada de mercancía':
        location = 'TIENDA'
          break
        default:
          console.log("Operación no validad")
          break
      }

      
      detected.forEach(item => {  
        //console.log(item.assetId)
        update(ref(db,  `pixels/${item.assetId}`), {
          locationSystem:location
        })
      })

      Swal.fire({
        //position: 'top-end',
        icon: 'success',
        title: 'Elementos validados correctamente',
        showConfirmButton: false,
        timer: 2500
      })

      this.clean()
      router.push('/')
    },

    clean(){
      this.$refs.inputFile.value=''
      this.localFile = null
      this.dataFile = []
      this.nameFile=''
    },

    selectFile(event){
      this.localFile = null
      this.dataFile = []
      this.nameFile=''
      //console.log(event.target.files[0])

      if(event.target.files[0]){
        this.localFile = event.target.files[0]
        this.nameFile = event.target.files[0].name
      
        if(this.localFile.type.match(/^.*csv/)){
        //console.log("Es CSV")
        const reader = new FileReader()
        
        reader.readAsText(this.localFile)
        reader.onload = e => {
          const rawArray = e.target.result.split(/\r?\n/)
          rawArray.forEach(item => {
            const mySplit = item.split(',')
            const myObj = {
              sku:mySplit[0],
              amount:parseInt(mySplit[1])
            }
            //console.log(myObj)
            this.dataFile.push(myObj)
          })
          //console.log(this.dataFile)
        }
        
        /* reader.readAsDataURL(this.localFile)
        reader.onload = e => {
          console.log("ETARGETRESULT "+e.target.result)
          this.urlFile = e.target.result*/

        } else {
          //console.log("No es CSV")
          this.snackbar=true
          this.localFile = null
          this.dataFile=[]
          this.nameFile=''
        }

      }
      
    },

  }
}




</script>